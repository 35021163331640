import tips from './tips'
import copay from './copay'
import delivery from './delivery'

export default {
  'approve-type': {
    'face-2-face-id-required': {
      label: 'Se requiere identificación presencial y firma',
      /* eslint-disable max-len */
      description_1: 'La receta se entregará únicamente al paciente asignado. El destinatario debe presentar su documento de identidad y su firma, y ​​el documento de identidad debe coincidir con el nombre del destinatario.',
      description_2: 'El conductor tomará una fotografía de la identificación del paciente y de la puerta en el momento de la entrega.',
    },
    'face-2-face-not-required': {
      label: 'No se requiere firma presencial',
      description: 'Se requiere la firma de cualquier adulto mayor de 18 años que viva con el paciente. El conductor tomará una fotografía de la puerta del paciente.',
    },
    'face-2-face-required': {
      label: 'Se requiere la firma de cualquier adulto que viva con el paciente.',
      description: 'Se requiere la firma de cualquier persona que viva con el paciente. El conductor tomará una fotografía de la puerta del paciente.',
    },
    'non-contract-delivery': {
      label: 'Entrega sin contacto',
      description: 'El conductor dejará el paquete en la puerta y tomará una fotografía, sin intentar la entrega cara a cara.',
    },
    'online-signature': {
      label: 'Firma en línea',
      description_1: 'El conductor dejará la receta en la puerta y tomará una fotografía, únicamente',
      description_2: 'Si el destinatario firma en línea antes de la entrega. Sin la firma en línea, el método de entrega será',
      description_3: 'Proceda como "Se requiere firma cara a cara".',
    },
    'signature-optional': {
      label: 'Firma opcional',
      description: 'El conductor intentará realizar la entrega en persona. Si no hay nadie en casa, el conductor dejará el paquete junto a la puerta y tomará una foto.',
    },
  },
  tips,
  copay,
  delivery,
  wait: 'Espere por favor...',
  'promised-eta': 'ETA prometida:',
  reschedule: 'Cambiar fecha u hora de entrega/Cambiar dirección',
}
